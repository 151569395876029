import React from 'react'
import Staff from './Staff'
import staff_data from '../staff.json'

const Family = () => {
  return (
    <section id="#family" className='my-20 lg:my-32 w-screen'>
    <div className='my-10 w-11/12 lg:w-9/12 mx-auto text-center'>
        <h2 className='text-3xl lg:text-4xl font-primary lowercase text-th-brown tracking-wider font-extrabold'>THE FAMILY</h2>
        <p className='font-secondary text-base text-black w-full lg:w-1/2 mx-auto mt-3 lg:mt-8'>The Farmhouse team is united by our shared love of sharing food. When you visit us, we cook for you like you're family.</p>
    </div>
    <div className='w-11/12 xl:w-9/12 mx-auto flex flex-col lg:flex-row justify-center items-center gap-10'>
   { staff_data.map(staff => <Staff props={staff} />)}
    </div>
    </section>
  )
}

export default Family