import React, {useEffect} from 'react'
import NavEvent from '../components/common/NavEvent'
import Footer from '../components/common/Footer'
import FooterSub from '../components/common/FooterSub';

const TakeOut = () => {
  useEffect(() => document.title = "The Farmhouse Vancouver | Take Out in Mount Pleasant", [])

  return (
    <div className="min-h-screen overflow-x-hidden">
    <NavEvent />
    <section className='relative bg-black w-screen h-[250px] lg:h-[280px] 2xl:h-[400px] rounded-bl-[50px] md:rounded-bl-[170px] lg:rounded-bl-[220px] overflow-hidden'>
    <div className='absolute top-0 left-0 bg-takeout bg-no-repeat bg-cover w-full h-full bg-opacity-25 opacity-60 bg-center'></div>
      <h1 className='text-white text-2xl lg:text-5xl font-primary text-center absolute top-1/2 left-1/2 pt-0 lg:pt-10 -translate-x-1/2'>take out</h1>
    </section>
   <section className='bg-group bg-no-repeat bg-right-bottom bg-[length:400px] md:bg-[length:700px] lg:bg-[length:1000px]'>
    <div className='pt-5 md:pt-16 pb-10 md:pb-20 w-10/12 md:w-9/12 xl:w-1/2 mx-auto'>
    <h2 className='text-2xl pb-3 font-primary lowercase'>A Taste of Italy, Rooted in the West Coast at your Doorstep</h2>
      <p>
      Enjoy the comfort of our kitchen in your home - a culinary experience that bridges continents and cultures, making every meal a special occasion. Whether it's a casual dinner or a momentous gathering, The Farmhouse take-out is your invitation to indulge in the familiar yet refined, the casual yet intimate all from the comfort of your own dining table. Now, through Uber Eats and Skip the Dishes, we're bringing this experience to you. Delight in our Italian-inspired fare, thoughtfully prepared with the finest ingredients sourced from local growers, farmers, and foragers across the valley.</p>
    <div className='flex flex-col md:flex-row justify-start items-start md:items-center gap-3 text-base xl:text-lg font-primary text-th-white pt-5 md:pt-10'>
    <a href="https://www.ubereats.com/ca/store/the-farmhouse/jP1sRy4iWFGJ5flpKRxGzQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1vZGElMjBIb3RlbCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMjg0Y2FhNjVjLTlhOTUtMWI2Ni1iNjI1LWJlM2RhNGZlNzc4NiUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJ1YmVyX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNDkuMjc5NjY3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTEyMy4xMjEwMTI3JTdE" rel="noreferrer" target='_blank' className='hover:text-th-red hover:bg-th-white border border-th-red py-2 px-3 rounded-md bg-th-red text-th-white'>uber eats</a>
    <a href="https://www.skipthedishes.com/the-farmhouse-east-10th-avenue" rel="noreferrer" target='_blank' className='hover:text-th-red hover:bg-th-white border border-th-red py-2 px-3 rounded-md bg-th-red text-th-white'>skip the dishes</a>
   
    </div>
    </div>
   </section>

    <Footer />
    <FooterSub />
    </div>
  )
}

export default TakeOut