import { useEffect } from 'react';
import './App.css';
import About from './components/About';
import Footer from './components/common/Footer';
import FooterSub from './components/common/FooterSub';
import Nav from './components/common/Nav';
import Family from './components/Family'; 
import FoodWine from './components/FoodWine';
import Menus from './components/Menus';
import Popup from './components/Popup';
import Reservations from './components/Reservations';
import { Map } from './components/Map';
import NavEvent from './components/common/NavEvent';

function App() {
  useEffect(() => document.title = "The Farmhouse Vancouver | Mount Pleasant Restaurant", [])

  return (
    <div className="min-h-screen overflow-x-hidden">
    <Popup />
    <NavEvent />
    {/* <section className='relative bg-black w-screen h-[450px] lg:h-[550px] 2xl:h-[650px] rounded-bl-[170px] lg:rounded-bl-[220px] overflow-hidden'> */}
    <section className='relative bg-black w-screen h-[450px] lg:h-[550px] 2xl:h-[720px] rounded-bl-[170px] lg:rounded-bl-[220px] overflow-hidden'>
    {/* <div className="absolute z-10 w-11/12 md:w-8/12 2xl:w-6/12 left-1/2 -translate-x-1/2 mt-12 md:mt-12 lg:mt-24 bg-black/60 md:bg-black/50 px-2 md:px-0 text-th-white overflow-hidden h-fit xl:max-h-[98px] pt-5 pb-1 md:py-5 flex flex-col md:flex-row justify-between items-center gap-0 md:gap-5">
      <div className='justify-center xl:flex'>
      <a href="assets/menus/The Farmhouse - Dine Out 2024.pdf" className='block' target='_blank' rel="noreferrer">
      <img  src='/assets/images/logos/dov.png'alt="dov" className='object-contain h-[26px] lg:h-[60px]' />
      </a>
      </div>
      <a href="assets/menus/The Farmhouse - Dine Out 2024.pdf" target="_blank" rel="noreferrer">
      <p className='font-primary lowercase text-sm md:text-xl lg:text-2xl'>View our 2024 Dine Out Menu</p>
      </a>
      <a href='https://www.tbdine.com/book/restaurant/the-farmhouse-restaurant?idApp=70973&language=en-us' className='text-white border border-white px-3 mt-1.5 md:mt-0 py-1 lg:py-2 font-primary rounded text-xs md:text-lg '>reserve now</a>
   </div> */}
    <div className='absolute top-0 left-0 bg-hero bg-no-repeat bg-cover w-full h-full bg-opacity-25 opacity-60 bg-center'></div>
      <img src='/assets/images/logos/farmhouse_logo_second.png' alt="farmhouse_logo" className='absolute w-2/3 lg:w-1/3 left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 object-contain' />
      <a href='https://www.tbdine.com/book/restaurant/the-farmhouse-restaurant?idApp=70973&language=en-us' target='_blank'rel="noreferrer" className={` absolute left-1/2 -translate-x-1/2 bottom-20 lg:bottom-32 tracking-widest text-base lg:text-xl cursor-pointer font-primary bg-th-red text-white py-2 px-3 rounded-md whitespace-nowrap lowercase transition-all hover:bg-white hover:text-th-red`}>RESERVE NOW</a>
    </section>
    <About />
    <Menus />
    <FoodWine />
    <Family />
    <section className='bg-th-red'>
    <Reservations />
    <Map />
    <Footer />
    </section>
    <FooterSub />
    </div>
  );
}

export default App;
