import React,{useEffect} from 'react'
import Footer from '../components/common/Footer'
import FooterSub from '../components/common/FooterSub';
import Nav from '../components/common/Nav';

const GroupBookingsForm = () => {
  useEffect(() => document.title = "The Farmhouse Vancouver | Group Dining and Group Bookings ", [])

  return (
    <div className="min-h-screen overflow-x-hidden">
    <Nav />
    <section className='relative bg-black w-screen h-[250px] lg:h-[280px] 2xl:h-[400px] rounded-bl-[50px] md:rounded-bl-[170px] lg:rounded-bl-[220px] overflow-hidden'>
    <div className='absolute top-0 left-0 bg-room bg-no-repeat bg-cover w-full h-full bg-opacity-25 opacity-60 bg-center'></div>
      <h1 className='text-white text-2xl lg:text-5xl font-primary text-center absolute top-1/2 left-1/2 pt-0 lg:pt-10 -translate-x-1/2'>group dining</h1>
    </section>
   <section className='bg-group bg-no-repeat bg-right-bottom bg-[length:400px] md:bg-[length:700px] lg:bg-[length:1000px]'>
    <div className='pt-5 md:pt-16 pb-10 md:pb-20 w-10/12 md:w-9/12 xl:w-1/2 mx-auto'>
    <h2 className='text-2xl pb-3 font-primary lowercase'>Private Dining and Group Functions</h2>
      <p>
      In the beautiful and quaint neighbourhood of Mount Pleasant, The Farmhouse offers an intimate, rustic experience with Northern Italian fare. The Farmhouse offers a few different configurations to host your group requirements from 10-52 people. Whether your hosting a corporate lunch, dinner, reception, birthday celebration, wedding or any occasion, our ‘farmhouse’ atmosphere is comfortable and inviting. Our venue offers everything your next gathering may necessitate, our delicious and creative group menus will satisfy any preference your guests may have. In addition we offer restaurant buyouts which are available and dependant on days of the week and time of year for exclusive privacy.
      <br />
      <span className='pt-2 inline-block'>
      Our intimate space can host a stand-up reception or a seated dinner for up to 52 guests. 
      </span>
      <br/>
      <span className='pt-2 inline-block'>
      To request a private event or dinner, please fill out the form by clicking the inquiry button below and we will contact you within one business day to discuss possible options based on your ideal date, number of guests, and any special requests you may have.
      </span>
      </p>
    <div className='flex flex-col md:flex-row justify-start items-start md:items-center gap-3 text-base xl:text-lg font-primary text-th-white pt-5 md:pt-10'>
    <a href="/groupbookings_form" className='hover:text-th-red hover:bg-th-white border border-th-red py-2 px-3 rounded-md bg-th-red text-th-white'>group booking inquiry</a>
    <a href="/assets/menus/the_farmhouse_events_package.pdf" target='_blank' className='text-th-red border border-th-red py-2 px-3 rounded-md hover:bg-th-red hover:text-th-white'>group booking package</a>
    <a href="/assets/menus/the_farmhouse_group_menu.pdf" target='_blank' className='text-th-red border border-th-red py-2 px-3 rounded-md hover:bg-th-red hover:text-th-white'>group booking menu</a>
    </div>
    </div>
   </section>

    <Footer />
    <FooterSub />
    </div>
  )
}

export default GroupBookingsForm