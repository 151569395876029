import React, { useState } from 'react'

const Staff = ({props}) => {
  const [open, setOpen] = useState(false)

  const handleModal = () => {
    setOpen((prev) => !prev)
  }
  const Bio = props.bio.split(/(\n)/g).map(t => (t === '\n')?<br/>:t)
  
  return (
    <>
    <div className='bg-th-brown w-full sm:w-9/12 md:w-1/2 lg:w-[300px] min-h-fit md:min-h-[500px] lg:min-h-[450px] flex flex-col justify-between items-center'>
      <div className='w-full h-72 sm:h-80 md:h-60 object-cover rounded-bl-[70px] bg-white'>
        <img src={props.img} alt="staff" className='w-full mx-auto h-72 sm:h-80 md:h-72 lg:h-60 object-cover rounded-bl-[70px]'/>
      </div>
        <h2 className='font-primary lowercase text-white text-2xl xl:text-3xl pt-2 lg:pt-5 text-center'>{props.name}</h2>
        <p className='font-secondary text-white px-2 lg:px-5 pt-3 lg:pt-5 text-center text-sm'>{props.title}</p>
        <button onClick={handleModal} className='mb-5 font-primary lowercase text-white text-lg tracking-widest text-center mt-4 lg:mt-8 border-white border-2 rounded-md py-1 px-9 transition-all hover:bg-white hover:text-th-brown'>MORE</button>
    </div>
    {open &&
    <div className='fixed top-0 left-0 bg-black/40 w-screen h-screen min-w-screen min-h-screen'>
      <div className='bg-th-brown text-white p-5 lg:p-14 text-center w-11/12 h-5/6 lg:w-9/12 lg:h-5/6 xl:h-4/6 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-start md:justify-around items-center max-h-[400px] md:max-h-screen rounded-bl-[50px] lg:rounded-bl-[70px]'>
        <h2 className='font-primary lowercase text-white tracking-widest text-xl lg:text-3xl pt-5 text-center'>{props.name}</h2>
        <p className='font-secondary text-white text-sm lg:text-base text-left mt-3 lg:mt-6 whitespace-pre-wrap overflow-y-scroll md:overflow-auto px-3 '>{Bio}</p>
        <button onClick={handleModal} className='mb-5 font-primary lowercase text-white tracking-widest text-center mt-4 lg:mt-8 border-white border-2 rounded-md py-2 px-8 transition-all hover:bg-white hover:text-th-brown'>Close</button>
      </div>
    </div>
    }
    </>  
  )
}

export default Staff