import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import ScrollToTop from './ScrollToTop';
import ContactPage from './pages/ContactPage';
import GroupBookings from './pages/GroupBookings';
import GroupBookingsForm from './pages/GroupBookingsForm';
import Press from './pages/Press';
import TakeOut from './pages/TakeOut';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<BrowserRouter>
  <ScrollToTop />
  <Routes>
    <Route path="/" element={<App />} />
    <Route path="contact" element={<ContactPage/>} />
    <Route path="groupbookings" element={<GroupBookings />} />
    <Route path="groupbookings_form" element={<GroupBookingsForm /> } />
    <Route path="take-out" element={<TakeOut />} />
    {/* <Route path="press" element={<Press />} /> */}
  </Routes>
</BrowserRouter>
);

reportWebVitals();
