import React, {useState, useEffect} from 'react'
import Footer from '../components/common/Footer'
import { useForm } from "react-hook-form";
import axios from 'axios'
import FooterSub from '../components/common/FooterSub';
import NavEvent from '../components/common/NavEvent';

const ContactPage = () => {
  useEffect(() => document.title = "The Farmhouse Vancouver | Contact Us", [])

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [submited, setSubmited] = useState(false)
    const [submiting, setSubmiting] = useState(false)
    const [isError, setIsError] = useState(false)
  
    const onSubmit = data => {
        console.log(data)
        setSubmiting(true)
        axios.post('https://farmhouseon10th.com/mail.php', {
            name: data.Name,
            email: data.Email,
            message: data.Message
        })
        .then(function (response) {
            setSubmited(true)
            setSubmiting(false)
            reset()
            setTimeout(() => {
            setSubmited(false)
            }, 8000);
          })
        .catch(function (error) {
            console.log(error);
            setSubmiting(false)
            setIsError(true)
          });
    }
  return (
    <div className="min-h-screen overflow-x-hidden">
    <NavEvent />
    <section className='mt-20 md:mt-32 mb-20 mx-auto'>
    <h1 className='font-primary text-center text-4xl md:text-5xl mb-5'>contact us
    </h1>
    <p className='text-center font-secondary pb-10 text-black text-base lg:text-lg px-5'>Get in touch! We would love to hear from you.</p>
    <div className='w-11/12 xl:w-10/12 mx-auto p-0 xl:p-5'>
    <div className='w-full h-full flex items-center justify-center flex-col lg:flex-row '>
    <div className='flex flex-col w-full lg:w-1/2 items-center justify-around'>
    <div className='w-full lg:w-10/12 mx-auto rounded-bl-[30px]'>
    <iframe className='rounded-bl-[30px] w-full h-[300px] lg:h-[350px]' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10415.071134059619!2d-123.0971147!3d49.2618472!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54867115048685c5%3A0x981ac2589b8eb47d!2sThe%20Farmhouse!5e0!3m2!1sen!2sca!4v1668726015864!5m2!1sen!2sca" loading="lazy" title="map"></iframe>
    </div>
    <div className='w-full lg:w-10/12 mx-auto bg-white text-black font-secondary'>
    <div className='flex flex-col md:flex-row justify-start items-center gap-5 lg:gap-8 py-7 lg:py-10'>
    <div className='font-secondary text-center md:text-left'>
    {/* <h5 className='font-primary lowercase leading-tight pb-2 text-lg'>GENERAL INQUIRIES</h5> */}
    <h5 className='font-primary lowercase leading-tight pb-1 text-lg'>the farmhouse</h5>
    <div className='flex items-start justify-center md:justify-start gap-2'>
    <div className='font-secondary text-center md:text-left'>
    <a href='tel: 604-260-2477' className='block'> 604.260.2477</a>
    <a href="mailto:info@farmhouseon10th.com" className='block'>info@farmhouseon10th.com</a>
    <h5 className='font-primary lowercase leading-tight pb-1 pt-2 text-lg'> MEDIA INQUIRIES</h5>
    <div className='flex items-start justify-center md:justify-start gap-2'>
    <div className='font-secondary text-center md:text-left'>
    <a href="mailto:media@viaggiohospitality.com" className='block'>media@viaggiohospitality.com</a>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div className='h-1 w-14 md:h-20 md:w-1 bg-th-red rounded-md'></div>
    <div className='font-secondary text-center md:text-left'>
    <h5 className='font-primary lowercase leading-tight pb-1 text-lg pt-3'>Event and Group Bookings</h5>
    <p className='mb-1'>Vanessa Rizzo</p>
    <div className='flex items-start justify-center md:justify-start gap-2'>
    <div className='font-secondary text-center md:text-left'>
    <a href='tel:604-219-1013' className='inline-block'>604.219.1013</a>
    <a href="mailto:bookings@viaggiohospitality.com" className='block'>bookings@viaggiohospitality.com</a>
    <a href="/groupbookings" className='lowercase font-primary bg-th-red text-white py-1 px-2 mt-2 block rounded-lg border border-white hover:bg-white hover:text-th-red hover:border-th-red'>View Group Booking package</a>
    </div>
    </div>
    </div> 
       </div>
    </div>
    </div>
        <div className='w-full lg:w-1/2 xl:w-1/3 mx-auto font-secondary bg-white px-1 lg:px-5 py-2 mt-8 lg:mt-0'>
            <h1 className='font-primary text-2xl lg:text-3xl text-center mt-1 lg:mt-3 mb-5'>send a message</h1>
            {/* <h2 className='text-lg lg:text-lg font-secondary text-center mt-1 lg:mt-3 mb-5'>We welsome your feedback!</h2> */}
            {submited && (
                <div className="alert bg-th-brown text-white rounded-md">
                <div className='flex justify-center items-center text-base lg:text-lg font-secondary mb-5 px-1 py-3 gap-3'>
                  <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                  <span>Thank you! Your submission has been sent.</span>
                </div>
                </div>
            )}
            {submiting && (
                <div className="alert bg-th-brown text-white rounded-md">
                <div className='flex justify-center items-center text-base lg:text-lg font-secondary px-1 py-3 gap-3 mb-5'>
                <svg aria-hidden="true" className="mr-2 w-8 h-8 text-gray-200 animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                 <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span>Sending...</span>
                </div>
              </div>
            )}
            {isError && (
                <div className="alert bg-th-brown text-white rounded-md">
                <div className='flex justify-center items-center text-base lg:text-lg font-secondary px-1 py-3 gap-3 mb-5'>
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span>Sorry. Something wrong...</span>
                </div>
              </div>
            )}
    <form onSubmit={handleSubmit(onSubmit)} method="POST">
    <div className="mb-3">
      <label className="block text-black text-base mb-2 font-primary tracking-wide">
        name
      </label>
      {errors.Name && (
        <div className="alert rounded-none p-0 pb-1 bg-transparent">
        <div className='flex'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-th-red flex-shrink-0 w-5 h-5"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          <span className='text-sm text-th-red'>Name is required</span>
        </div>
      </div>
      )}
      <input {...register("Name",  { required: true })}  className="rounded-bl-2xl border border-th-red w-full py-2 px-3 text-black focus:outline-none focus:shadow-outline" type="text"  />
    </div>
    <div className="mb-3">
      <label className="block text-black text-base mb-2 font-primary tracking-wide">
      email address
      </label>
      {errors.Email && (
        <div className="alert rounded-none p-0 pb-1 bg-transparent">
        <div className='flex'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-th-red flex-shrink-0 w-5 h-5"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          <span className='text-sm text-th-red'>Email Address is required</span>
        </div>
      </div>
      )}
      <input {...register("Email", { required: true })} className="rounded-bl-2xl border border-th-red w-full py-2 px-3 text-black mb-3 focus:outline-none focus:shadow-outline" type="e-mail" />
    </div>
    <div className="mb-3">
      <label className="block text-black text-base mb-2 font-primary tracking-wide">
        message
      </label>
      {errors.Message && (
        <div className="alert rounded-none p-0 pb-1 bg-transparent">
        <div className='flex'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-th-red flex-shrink-0 w-5 h-5"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          <span className='text-sm text-th-red'>Message is required</span>
        </div>
      </div>
      )}
      <textarea rows='5' {...register("Message", { required: true })} className="rounded-bl-2xl border border-th-red w-full py-2 px-3 text-black mb-3 focus:outline-none focus:shadow-outline" />
    </div>
        <div className="flex items-center justify-center">
        <input type="submit" className='font-primary cursor-pointer tracking-wider px-10 py-3 rounded-md bg-th-red text-white hover:-translate-y-1 hover:bg-white hover:text-th-red hover:border-th-red hover:border transition-all' value={submiting ? "sending...":'submit'} />
    </div>
    </form>
    </div>
        </div>
        </div>
    </section>
    <Footer />
    <FooterSub />
    </div>
  )
}

export default ContactPage