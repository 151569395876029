import React from 'react'

const FoodWine = () => {
  return (
    <div className='bg-th-red w-screen py-5 lg:py-12 flex flex-col justify-center items-center gap-5 lg:gap-10'>
      <div className='bg-white w-11/12 md:w-6/12 lg:w-11/12 mx-auto h-auto lg:h-[500px] xl:h-[450px] '>
        <div className='w-full h-fit flex flex-col-reverse lg:flex-row items-center justify-between lg:justify-center gap-0 lg:gap-10'>
          <div className='w-full lg:w-2/3 h-2/3 lg:h-full py-7 lg:py-0 px-7 md:px-10 xl:px-14 2xl:px-40'>
            <h2 className='font-primary lowercase text-2xl lg:text-4xl text-th-brown font-extrabold tracking-widest'>FOOD & WINE</h2>
            <p className='font-secondary text-base text-black mt-5 lg:mt-5'>Chef Curtis cooks for you like you're sitting at his kitchen table. Elevated comfort food, Chef's dishes are designed to be shared amongst friends and family.</p>
            <p className='font-secondary text-base text-black mt-1 lg:mt-2'>Our stockrooms are full of ingredients that are as close to home as possible — especially our herbs, which come straight from the backyard garden. Many of the elements on your plate are grown at the small-scale, locally-owned Campagna Farm in Abbotsford.</p>
            <p className='font-secondary text-base text-black mt-1 lg:mt-3'>Organic, natural, and biodynamic wines make up our list — wines that taste like the place they came from. Rustic and memorable, our vintages inspire warmth and comfort.</p>
            <p className='font-secondary text-base text-black mt-1 lg:mt-2'>The wine program will focus on the wines of Italy, BC and the greater Pacific Northwest with a few standouts from the well known wine regions of France.</p>
            <p className='font-secondary text-base text-black mt-1 lg:mt-2'>The wine list has been curated with sustainability in mind with many of the wines being biodynamic or organically grown.</p>
          </div>
          <img src="assets/images/food.jpg" alt="food_and_wine" className='w-full lg:w-1/3 h-1/3 lg:h-[500px] xl:h-[450px] object-cover max-h-[250px] lg:max-h-[600px]' />
        </div>
      </div>
      <div className='bg-white w-11/12 md:w-6/12 lg:w-11/12 mx-auto h-auto lg:h-[500px] xl:h-[450px]'>
        <div className='w-full h-fit flex flex-col lg:flex-row items-center justify-between lg:justify-center gap-0 lg:gap-10'>
          <img src="assets/images/room.jpg" alt="food_and_wine"className='w-full lg:w-1/3 h-1/3 lg:h-[500px] xl:h-[450px] object-cover max-h-[250px] lg:max-h-[600px]'/>
          <div className='w-full lg:w-2/3 h-2/3 lg:h-full py-7 lg:py-0 px-7 md:px-10 xl:px-14 2xl:px-40'>
            <h2 className='font-primary lowercase text-2xl lg:text-4xl text-th-brown font-extrabold tracking-widest'>Our Room and Patio</h2>
            <p className='font-secondary text-base text-black mt-5 lg:mt-10'>Come in, sit down, and let us take care of you. The Farmhouse rests on the main floor of a traditional, restored Vancouver home and in our house, you're always welcome. Inspired by the farmhouses that dot Italy's countryside, our space embraces a down-to-earth, homespun lifestyle. When you walk through our front doors, you're family.</p>
            <p className='font-secondary text-base text-black mt-2 lg:mt-5'>You can sit at the short service bar in front of the open kitchen to watch Chef in action. Take your time eating and drinking in the greater dining room, or, on warm nights, sit on our backyard patio for an alfresco experience.</p>
          </div>
        </div>
        </div>
    </div>
  )
}

export default FoodWine