import React from 'react'

const Reservations = () => {
  return (
    <div id="reservations" className='bg-th-red'>
      <div className='flex flex-col justify-center items-center gap-5 pt-10 pb-3 lg:pb-10'>
        <h1 className='font-primary lowercase text-3xl lg:text-4xl text-white font-extrabold tracking-widest text-center'>
        Reservations
        </h1>
        <p className=' font-secondary text-white text-center'>Online reservations available for groups of 6 or less. <br />Patio seating is available by a first-come, first-serve basis. We appreciate your cooperation. </p>
        <a target='_blank'rel="noreferrer" href='https://www.tbdine.com/book/restaurant/the-farmhouse-restaurant?idApp=70973&language=en-us' className="font-primary lowercase text-lg lg:text-2xl cursor-pointer bg-th-brown text-th-white px-6 py-1 rounded-md transition-all hover:bg-white hover:text-th-brown">RESERVE NOW</a>
      </div>
    </div>
  )
}

export default Reservations